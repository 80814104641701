import { template as template_ede7b68438c549e1a10572ce98926ee9 } from "@ember/template-compiler";
const FKLabel = template_ede7b68438c549e1a10572ce98926ee9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
