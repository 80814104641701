import { template as template_87957a77f5994355b825d07a48d5f125 } from "@ember/template-compiler";
const FKControlMenuContainer = template_87957a77f5994355b825d07a48d5f125(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
