import { template as template_1a047ceacaf04a14841bd462f91eb4f3 } from "@ember/template-compiler";
const SidebarSectionMessage = template_1a047ceacaf04a14841bd462f91eb4f3(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
