import { template as template_3165a60abdd84abea5a9816c9d20caec } from "@ember/template-compiler";
const FKText = template_3165a60abdd84abea5a9816c9d20caec(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
